import React from "react";
import NotFound from "../assets/Images/ArticleWhitePaper/404.png";
import SEO from "../templates/seo";

const NotFoundPage = () => (
  <div>
    <SEO title="404: Not found" />
    <img style={{width:'100%', height:'100%'}} src={NotFound} alt="Page not found" />
  </div>
)

export default NotFoundPage
