/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";
import DigiryteLogo from "../assets/Images/Homepage/Digiryte-logo.svg";
function SEO({
  description,
  lang,
  meta,
  title,
  ogimage,
  ogurl,
  ogtitle,
  keywords,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            keywords
          }
        }
      }
    `
  );
  const metaDescription = description || site.siteMetadata.description;
  const metaKeywords = keywords || site.siteMetadata.keywords;
  const metaTitle = ogtitle || title || site.siteMetadata.title;
  const schemaOrgJSONLD = [
    {
      "@context": "https://schema.org",
      "@type": "Organization",
      name: "Digiryte",
      description:
        "We are UK’s leading software consultancy, building next-generation web and mobile applications, and providing access to industry-leading technical talent.",
      email: "hello@digiryte.com",
      image: `${DigiryteLogo}`,
      "@id": "",
      url: "https://www.digiryte.com/",
      telephone: "44 161 706 7983",
      address: {
        "@type": "PostalAddress",
        addressCountry: "UK",
        postalCode: "M50 2ST",
        streetAddress: "Blue Tower, MediaCityUK, Salford, Greater Manchester",
      },
      founder: {
        "@type": "Person",
        name: "Ramesh Raja",
        gender: "Male",
        jobTitle: "CEO",
        image:
          "https://www.digiryte.com/static/Ramesh-789efa27cf2cee28291bf149a2b86d4f.png",
        sameAs: [
          "https://twitter.com/botsbreeder",
          "https://www.linkedin.com/in/dmitrygerasimenko/",
        ],
      },
      foundingDate: "2010-07-15",
      geo: {
        "@type": "GeoCoordinates",
        latitude: 53.4770585,
        longitude: -2.2439517,
      },
      sameAs: [
        "https://www.facebook.com/digiryte/",
        "https://twitter.com/digiryte",
        "https://www.linkedin.com/company/digiryte",
        "https://www.digiryte.com/",
      ],
      contactPoint: [
        {
          "@type": "ContactPoint",
          contactType: "customer service",
          email: "hello@digiryte.com",
          url: "https://www.digiryte.com",
        },
      ],
    },
  ];
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        // titleTemplate={`%s | ${site.siteMetadata.title}`}

        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            name: "keywords",
            content: metaKeywords,
          },
          {
            property: `og:locale`,
            content: `en_GB`,
          },
          {
            property: `og:type`,
            content: `article`,
          },
          {
            property: "og:image",
            content: ogimage,
          },
          {
            property: `og:image:width`,
            content: `1200px`,
          },
          {
            property: `og:image:height`,
            content: `630px`,
          },
          {
            property: "og:url",
            content: ogurl || site.siteMetadata.siteUrl,
          },
          {
            property: `og:title`,
            content: metaTitle,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            name: `twitter:card`,
            content: `summary_large_image`,
          },
          {
            name: "twitter:image",
            content: ogimage,
          },
          {
            name: `twitter:site`,
            content: `@digiryte`,
          },
          {
            name: `twitter:title`,
            content: metaTitle,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
        ].concat(meta)}
      >
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD)}
        </script>
      </Helmet>
      {metaDescription && <p className="sr-only">{metaDescription}</p>}
    </>
  );
}
SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};
SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};
export default SEO;
